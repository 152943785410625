<template>
  <Layout>
    <div class="float-right mb-4">
      <b-button class="bseen-btn" @click="showActionModal">Add</b-button>
    </div>
    <div class="clearfix"></div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="bankData"
                :fields="fields"
                responsive="sm"
                :busy="isBusy"
                :per-page="perPage"
                :current-page="currentPage"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </template>
                <template #cell(status)="row">
                  {{row.value === 0? "Active": "Inactive"}}
                </template>
                <template #cell(actions)="row">
                  <button class="action-btn" @click="deleteBank(row.item)">
                    <i class="fas fa-trash-alt"></i>
                  </button>
                  <button class="action-btn" @click="editBank(row.item)">
                    <i class="fas fa-edit"></i>
                  </button>
                </template>
                <template #cell(permissions)="row">
                  {{ row.item.permissions.length || 0 }} permissions
                </template>
          
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <BanksModal
      :selectedBank="selectedBank"
      v-on:resetModal="selectedBank = null"
      v-on:reloadData="getAllBanks"
      v-on:closeModal="hideActionModal"
    />
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import { banks } from "@/config/api/banks";
import BanksModal from "@/components/banks/banks-modal";

export default {
  components: { Layout, BanksModal },

  data() {
    return {
      bankData: [],
      selectedBank: null,
      isBusy: false,
      totalRows: 0,
      currentPage: 1,
      filter: null,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      sortBy: "name",
      sortDesc: false,
      fields: [
        { key: "name", sortable: false },
        { key: "status", sortable: false },
        { key: "actions", sortable: false },
      ],
    };
  },
  computed: {
    rows() {
      return this.bankData.length;
    },
  },
  mounted() {},
  created() {
    this.getAllBanks();
  },
  methods: {
    getAllBanks() {
      this.isBusy = true;
      const api = banks.get;
      this.generateAPI(api)
        .then((res) => {
          this.bankData = res.data.banks;
          this.totalRows = this.bankData.length;
          this.isBusy = false;
        })
        .catch((err) => {
          console.error(err.response.data);
        });
    },
    editBank(data) {
      this.selectedBank = data;
      this.showActionModal();
    },
    showActionModal() {
      this.$bvModal.show("action-Bank");
    },
    hideActionModal() {
      this.selectedBank = {}
      this.$bvModal.hide("action-Bank");
    },
    deleteBank(data) {
      this.$bvModal
        .msgBoxConfirm(`Please confirm that you want to delete ${data.name}.`, {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            const api = banks.delete;
            api.id = data._id;
            this.generateAPI(api)
              .then((res) => {
                console.log(res.data);
                this.$bvToast.toast("Bank deleted successfully!", {
                  title: "Delete",
                  variant: "success",
                  solid: true,
                });
                this.getAllBanks();
              })
              .catch((err) => {
                this.$bvToast.toast("Bank is not deleted", {
                  title: "Delete",
                  variant: "danger",
                  solid: true,
                });
                console.error(err.response.data);
              });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>