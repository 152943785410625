<template>
  <b-modal id="action-Bank" @hidden="$emit('resetModal')" :title="selectedBank ? 'Update Bank' : 'Add Bank'"
    hide-footer>
    <FormulateForm name="BankAction" v-model="values" :schema="schema" @submit="submitHandler">
      <FormulateInput name="status" v-model="status" type="checkbox" label="Active" />
      <FormulateInput type="submit" class="float-right">
        <span v-if="performAction">
          <b-spinner class="vueformulate-loader"></b-spinner>
          <span v-if="selectedBank"> Updating... </span>
          <span v-else> Adding... </span>
        </span>
        <span v-else>
          <span v-if="selectedBank"> Update </span>
          <span v-else> Add </span>
          Bank</span>
      </FormulateInput>
    </FormulateForm>
  </b-modal>
</template>

<script>
import { banks } from "@/config/api/banks";
export default {
  props: ["selectedBank"],
  data() {
    return {
      status: false,
      values: {},
      performAction: false,
      schema: [
        {
          index: 0,
          type: "text",
          name: "name",
          label: "Name English",
          validation: "required",
        },
        {
          index: 1,
          type: "text",
          name: "name_ar",
          label: "Name Arabic",
          validation: "required",
        },
      ],
    };
  },
  methods: {
    async submitHandler(data) {
      if (this.selectedBank) {
        this.updateBank(data, this.selectedBank._id);
      } else {
        this.addBank(data);
      }
    },
    addBank(data) {
      this.performAction = true;
      const api = banks.create;
      api.data = { ...data, status: data.status ? 0 : -1 };
      this.generateAPI(api)
        .then((res) => {
          console.log(res.data);
          this.$formulate.reset("BankAction");
          this.$bvToast.toast("Bank added successfully!", {
            title: "Create",
            variant: "success",
            solid: true,
          });

          this.$emit("reloadData");
          this.$emit("closeModal");
        })
        .catch((err) => {
          this.$bvToast.toast("Bank is not added!", {
            title: "Create",
            variant: "danger",
            solid: true,
          });
          console.error(err.response.data);
        })
        .finally(() => {
          this.performAction = false;
        });
    },
    updateBank(data, id) {
      this.performAction = true;
      const api = banks.update;
      api.data = { ...data, status: data.status ? 0 : -1 };
      api.id = id;
      this.generateAPI(api)
        .then((res) => {
          console.log(res.data);
          this.$bvToast.toast("Bank updated successfully!", {
            title: "Update",
            variant: "success",
            solid: true,
          });
          this.$emit("reloadData");
        })
        .catch((err) => {
          this.$bvToast.toast("Bank is not updated!", {
            title: "Update",
            variant: "danger",
            solid: true,
          });
          console.error(err.response.data);
        })
        .finally(() => {
          this.performAction = false;
        });
    },
  },
  watch: {
    selectedBank(val) {
      if (val) {
        this.values = { ...val };
        if (val.status === 0) this.status = true;
        else this.status = false;
      }
    },
  },
};
</script>

<style>
.payment-duration .formulate-input-group {
  display: flex;
}

.payment-duration .formulate-input-group-item {
  margin: 8px;
}

.payment-duration .formulate-input-label {
  margin-top: -6px;
}
</style>
